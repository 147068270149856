<template>
  <div class="p-8">
        <ValidationObserver ref="datos_basicos">
            <div>

              <validation-provider
                  v-slot="{ errors }"
                  name="fields_usuario.group_datos_basicos.json_institucion.json_institucion"
                  :rules=fields_usuario.group_datos_basicos.json_institucion.caracteristicas.required
              >
                  <v-autocomplete
                  v-model="fields_usuario.group_datos_basicos.json_institucion.json_institucion"
                  :items="list_institucion"
                  :error-messages="errors"
                  chips
                  outlined
                  color="blue-grey lighten-2"
                  :label=fields_usuario.group_datos_basicos.json_institucion.caracteristicas.label
                  item-text="tx_razon_social"
                  item-value="nu_id_institucion"
                  multiple
                  return-object
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                      >
                      {{ data.item.tx_razon_social }}
                      </v-chip>
                  </template>
                  </v-autocomplete>
              </validation-provider>
            </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_usuario.group_datos_basicos.tx_username.tx_username"
                :rules=fields_usuario.group_datos_basicos.tx_username.caracteristicas.required
              >
                <v-text-field
                  v-model="fields_usuario.group_datos_basicos.tx_username.tx_username"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_usuario.group_datos_basicos.tx_username.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_usuario.isEditing                            
                ></v-text-field>
              </validation-provider>     
                </template>
                <span>{{fields_usuario.group_datos_basicos.tx_username.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
            <validation-provider
              v-slot="{ errors }"
              name="fields_usuario.group_datos_basicos.tx_nacionalidad.tx_nacionalidad"
              :rules=fields_usuario.group_datos_basicos.tx_nacionalidad.caracteristicas.required
            >
                    <v-select
                      v-model="fields_usuario.group_datos_basicos.tx_nacionalidad.tx_nacionalidad"
                      :items="d_nacionalidad"
                      class="input-group--focused text-lg"
                      :placeholder=fields_usuario.group_datos_basicos.tx_nacionalidad.caracteristicas.placeholder
                      :error-messages="errors"
                      :label=fields_usuario.group_datos_basicos.tx_nacionalidad.caracteristicas.label
                      outlined
                      :disabled=!fields_usuario.isEditing
                    ></v-select>                      

            </validation-provider>                    
          </div>
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="fields_usuario.group_datos_basicos.tx_documento_identidad.tx_documento_identidad"
                    :rules=fields_usuario.group_datos_basicos.tx_documento_identidad.caracteristicas.required
                  >

                    <v-text-field
                      v-model="fields_usuario.group_datos_basicos.tx_documento_identidad.tx_documento_identidad"
                      class="input-group--focused text-lg"
                      :error-messages="errors"
                      :label=fields_usuario.group_datos_basicos.tx_documento_identidad.caracteristicas.label 
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      maxlength="10"
                      :disabled=!fields_usuario.isEditing                            
                    >
                    </v-text-field>
                  </validation-provider>                    
              </template>
              <span>{{fields_usuario.group_datos_basicos.tx_documento_identidad.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>
          <div>         
            <validation-provider
              v-slot="{ errors }"
              name="fields_usuario.group_datos_basicos.nu_sexo.nu_sexo"
              :rules=fields_usuario.group_datos_basicos.nu_sexo.caracteristicas.required
            >
                <v-select
                  v-model="fields_usuario.group_datos_basicos.nu_sexo.nu_sexo"
                  :items="d_sexo"
                  class="input-group--focused text-lg pt-4"
                  :error-messages="errors"
                  :label=fields_usuario.group_datos_basicos.nu_sexo.caracteristicas.label
                  outlined
                  :disabled=!fields_usuario.isEditing                            
                ></v-select>                      
            </validation-provider>   
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_usuario.group_datos_basicos.tx_apellidos.tx_apellidos"
                :rules=fields_usuario.group_datos_basicos.tx_apellidos.caracteristicas.required
              >
                <v-text-field
                  v-model="fields_usuario.group_datos_basicos.tx_apellidos.tx_apellidos"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_usuario.group_datos_basicos.tx_apellidos.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_usuario.isEditing                            
                ></v-text-field>
              </validation-provider>     
                </template>
                <span>{{fields_usuario.group_datos_basicos.tx_apellidos.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_usuario.group_datos_basicos.tx_nombres.tx_nombres"
                :rules=fields_usuario.group_datos_basicos.tx_nombres.caracteristicas.required
              >
                <v-text-field
                  v-model="fields_usuario.group_datos_basicos.tx_nombres.tx_nombres"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_usuario.group_datos_basicos.tx_nombres.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_usuario.isEditing                            
                ></v-text-field>
              </validation-provider>     
                </template>
                <span>{{fields_usuario.group_datos_basicos.tx_nombres.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_usuario.group_datos_basicos.tx_direccion.tx_direccion"
                :rules=fields_usuario.group_datos_basicos.tx_direccion.caracteristicas.required
              >
                <v-textarea
                  v-model="fields_usuario.group_datos_basicos.tx_direccion.tx_direccion"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_usuario.group_datos_basicos.tx_direccion.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_usuario.isEditing                            
                ></v-textarea>
              </validation-provider>     
                </template>
                <span>{{fields_usuario.group_datos_basicos.tx_direccion.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_usuario.group_datos_basicos.sw_activo.sw_activo"
                :rules=fields_usuario.group_datos_basicos.sw_activo.caracteristicas.required
              >
              <v-checkbox
                  v-model="fields_usuario.group_datos_basicos.sw_activo.sw_activo"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_usuario.group_datos_basicos.sw_activo.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_usuario.isEditing                            
                ></v-checkbox>              
              </validation-provider>     
                </template>
                <span>{{fields_usuario.group_datos_basicos.sw_activo.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
                <!-- Inicio Boton de Navegacion -->
            <div v-if="send_usuario.operacion == 'EDITAR'" class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

          </ValidationObserver>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_INSTITUCION  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'UsuarioDatosBasicos',
  data () {
    return {
        modal: false,                 
        search:null,
        isLoading: false,
        list_institucion: [],
        d_nacionalidad: [
            { value: 'V', text: 'Venezolana' },
            { value: 'E', text: 'Extranjera', 
              disabled: false 
            }
          ],
        d_sexo: [
                  { value: 1, text: 'Femenino' },
                  { value: 2, text: 'Masculino', 
                    disabled: false 
                  }
                ],                        
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
 
  },     
  computed: {
    ...mapGetters(['fields_usuario']),
      listaInstitucion () {
          return this.list_institucion.map(entry => {
              const Description = entry.tx_razon_social.length > this.descriptionLimit
              ? entry.tx_razon_social.slice(0, this.descriptionLimit) + '...'
              : entry.tx_razon_social

              return Object.assign({}, entry, { Description })
          })
      },       
  },
  props: {
    send_usuario: Object
  },
  components: {

  },    
  methods: {
    remove (item) {
      var index = this.fields_usuario.group_datos_basicos.json_institucion.json_institucion.findIndex(i => i.nu_id_institucion === item.nu_id_institucion);
      console.log("Valor a remover: ", index)
      if (index >= 0) this.fields_usuario.group_datos_basicos.json_institucion.json_institucion.splice(index, 1)
    },       
    searchInstitucion(q) {
      this.$store.dispatch(LIST_INSTITUCION, {q: q}).then(response => {
          console.log('searchLIST_PERFIL: ', response )
          this.list_institucion = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },    
    async guardar () {
        const isValid = await this.$refs.datos_basicos.validate()
        if (!isValid) {
            return true
        }
        this.fields_usuario.group_datos_basicos.json_institucion.json_institucion = JSON.stringify(this.fields_usuario.group_datos_basicos.json_institucion.json_institucion)
        this.$emit("guardar_usuario_datos_basicos", JSON.stringify(this.fields_usuario.group_datos_basicos));
        this.fields_usuario.group_datos_basicos.json_institucion.json_institucion = JSON.parse(this.fields_usuario.group_datos_basicos.json_institucion.json_institucion)
    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

    this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          




  },
  
  mounted() {
      this.searchInstitucion()
      //var index = this.fields_usuario.group_datos_basicos.json_institucion.json_institucion.findIndex(i => i.nu_id_institucion === item.nu_id_institucion);
      //console.log("Valor a remover: ", index)

      //console.log('Tx usuario: ', this.fields_usuario.group_datos_basicos.json_perfil.json_perfil)
  },
    
}
</script>