<template>
  <div class="">
      <div>
        <InstitucionHeader :titulo="titulo" /> 
      </div>  

    <div class="md:flex md:items-start">
      <div class="w-full">
          <ul class="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tabFill"
            role="tablist">
            <li class="nav-item flex-auto text-center" role="presentation">
              <a href="#tabs-homeFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="tabs-home-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-homeFill" role="tab"
                aria-controls="tabs-homeFill" aria-selected="true">Usuarios</a>
            </li>
            <li class="nav-item flex-auto text-center" role="presentation">
              <a href="#tabs-profileFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-profile-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-profileFill" role="tab"
                aria-controls="tabs-profileFill" aria-selected="false">Perfiles</a>
            </li>
          </ul>
          <div class="tab-content" id="tabs-tabContentFill">
            <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
              <NotificacionInterna :mensaje="mensaje" />  
            </div>

            <div class="tab-pane fade show active" id="tabs-homeFill" role="tabpanel" aria-labelledby="tabs-home-tabFill">
                <div class=" border-2 rounded-md shadow-md">
                  <Usuario titulo="" @guardar_usuario_datos_basicos="return_usuario_datos_basicos"/>        
                </div>
            </div>
            <div class="tab-pane fade" id="tabs-profileFill" role="tabpanel" aria-labelledby="tabs-profile-tabFill">
                  <Perfiles titulo="" @guardar_usuario_datos_basicos="return_usuario_datos_basicos" :send_usuario="send_usuario"/>        
            </div>
          </div>        
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_INSTITUCION, UPDATE_INSTITUCION,  LIST_INSTITUCION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import Usuario from "@/views/configuracion/Seguridad/Usuario/Usuario";
import Perfiles from "@/views/configuracion/Seguridad/Perfiles/Perfiles";

import InstitucionHeader from "@/components/comunes/HeaderModulo.vue";

const moment = require('moment');

  export default {
    data() {
        return {
          consultasmedica: [],
          send_usuario: {},
          tabs: {
            group_usuario:{
              active: '',
              class: ''
            },
          },
          fields_usuario_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupos:'',
            fields_usuario: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_usuario','fields_persona']),
        hoy() {
            const today = new Date();
            var fecha2 = moment(today);
            return fecha2.format('DD-MM-YYYY');
        },            

    },    
    props: {
        titulo: String
    },
    components: {
      NotificacionInterna,
      Usuario,
      InstitucionHeader,
      Perfiles
    },
    methods: {
        async limpiar () {
            for (const property in this.fields_usuario) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_usuario[property]) {
                          this.fields_usuario[property][property_01][property_01] = ''
                      }
                  }
            }
            for (const property in this.fields_persona) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_persona[property]) {
                          this.fields_persona[property][property_01][property_01] = ''
                      }
                  }
            }



        },
        searchInstitucion(q) {
          console.log('Paciente: ', q)
          this.$store.dispatch(LIST_INSTITUCION, {nu_id_usuario: q}).then(response => {
              this.consultasmedica = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },  
        handleClick(e) {
          this.locales.grupos = this.fields_usuario.[e]
        },      
        cerrar(msg) {
          console.log('actualizar_datos_usuario: Actualizar')
          this.$emit("actualizar_datos_usuario", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },
        return_datos_usuario_especialidad(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            guardar.json_especialidad.json_especialidad = JSON.stringify(guardar.json_especialidad.json_especialidad)
            const formData = new FormData()
            formData.append('usuario', JSON.stringify(guardar))
            formData.append('nu_id_usuario', this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario)

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              this.$store.dispatch(UPDATE_INSTITUCION, formData).then(datos => {
                guardar.btn_guardar = 'true';
                  this.mensaje.satisfactorio.texto = "Modificación de la institución " + JSON.parse(datos.datos_usuario).nu_id_usuario + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                  //this.$refs.tabs_contacto_tabJustify.click();
              
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })


          /// hasta aca optimizar
        },    
        return_datos_redes_sociales(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            
            console.log('fields_persona.guardar_ubigeo: ', guardar)
            console.log('fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario: ', this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario)
            

            console.log('Institucion: ', this.fields_usuario.group_datos_ubigeo)

            const formData = new FormData()
            formData.append('usuario', JSON.stringify(guardar))
            formData.append('nu_id_usuario', this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario)

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              this.$store.dispatch(UPDATE_INSTITUCION, formData).then(datos => {
                guardar.btn_guardar = 'true';
                  this.mensaje.satisfactorio.texto = "Modificación de la institución " + JSON.parse(datos.datos_usuario).nu_id_usuario + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                  //this.$refs.tabs_contacto_tabJustify.click();
              
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })


          /// hasta aca optimizar
        },    
        return_datos_ubigeo(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            
            console.log('fields_persona.guardar_ubigeo: ', guardar)
            console.log('fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario: ', this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario)
            

            console.log('Institucion: ', this.fields_usuario.group_datos_ubigeo)

            const formData = new FormData()
            formData.append('usuario', JSON.stringify(guardar))
            formData.append('nu_id_usuario', this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario)

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              this.$store.dispatch(UPDATE_INSTITUCION, formData).then(datos => {
                guardar.btn_guardar = 'true';
                  this.mensaje.satisfactorio.texto = "Modificación de la institución " + JSON.parse(datos.datos_usuario).nu_id_usuario + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                 
                  this.$refs.tabs_contacto_tabJustify.click();
              
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })


          /// hasta aca optimizar
        },    
        // Paso #2
        return_datos_contacto(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            
            console.log('fields_persona.guardar_contacto: ', guardar)
            console.log('fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario: ', this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario)
            


            const formData = new FormData()
            formData.append('usuario', JSON.stringify(guardar))
            formData.append('nu_id_usuario', this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario)

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              this.$store.dispatch(UPDATE_INSTITUCION, formData).then(datos => {
                guardar.btn_guardar = 'true';
                  this.mensaje.satisfactorio.texto = "Modificación de la institución " + JSON.parse(datos.datos_usuario).nu_id_usuario + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    

                  this.$refs.tabs_redes_tabJustify.click();
              
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })


          /// hasta aca optimizar
        },    
        // Paso #2
        return_usuario_datos_basicos(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            console.log('return_usuario_datos_basicos: ', guardar)
            const formData = new FormData()
            formData.append('usuario', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (this.send_usuario.operacion === 'EDITAR') {
                formData.append('oper', 'EDITAR')
                
                this.$store.dispatch(UPDATE_INSTITUCION, formData).then(datos => {
                  guardar.btn_guardar = 'true';
                  this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario = datos.nu_id_usuario
                  //this.fields_usuario.group_usuario.tx_usuario.tx_usuario = datos.tx_usuario
                  setTimeout(() => {
                      // this.isLoading = false
                        console.log('message emit from child component', datos)
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                        });      

                    },3000)                  
                    this.$refs.tabs_enfermedadactual_tabJustify.click();

                  
                }, error => {
                    guardar.btn_guardar = 'false';
                    this.$store.commit(SET_ERROR, error.statusText)
                })

            } else {
              //formData = JSON.parse(JSON.stringify(guardar.formData))

              formData.append('oper', 'REGISTRO')

              this.$store.dispatch(CREATE_INSTITUCION, formData).then(datos => {
                  this.fields_usuario.group_datos_basico.nu_id_usuario.nu_id_usuario = JSON.parse(datos.datos_usuario).nu_id_usuario
                  this.mensaje.satisfactorio.texto = "Registro de la institución " + JSON.parse(datos.datos_usuario).nu_id_usuario + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             
                  console.log("Cancelar: " + guardar.btn_guardar);

              })  
          }     

          /// hasta aca optimizar
            
        },    



    },
    mounted() {
      if (this.send_usuario.operacion === 'EDITAR') {
            for (const property in this.fields_usuario) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_usuario[property]) {
                          this.fields_usuario[property][property_01][property_01] = this.send_usuario.usuario[property_01]
                      }
                  }
            }
          this.fields_usuario.group_datos_basico.json_tipo_persona.json_tipo_persona = JSON.parse(this.send_usuario.usuario.json_tipo_persona)

      } else {
              if (this.send_usuario.operacion === 'REGISTRAR') {
                  this.limpiar()
              }
      }            
    },         

    
  }
</script>

<style lang="scss" scoped>

</style>