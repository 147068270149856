var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pl-2 pr-2"},[_c('div',{staticClass:"pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black"},[_c('v-text-field',{attrs:{"clearable":"","prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Buscador"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"rounded-lg"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.usuario,"items-per-page":5,"expanded":_vm.expanded,"item-key":"nu_id_usuario","show-expand":"","search":_vm.search},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.registrar_consulta(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiBookPlusMultiple)+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.tx_nombres",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.tx_nombres))])])]}},{key:"item.tx_apellidos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.tx_apellidos))])])]}},{key:"item.nu_sexo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.nu_sexo === 1)?_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v("Femenino")]):_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v("Masculino")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',{staticClass:"font-bold"},[_vm._v("Pertiles")]),_vm._l((JSON.parse(item.json_institucion_perfil)),function(item_01,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item_01.tx_nombre_perfil)+" ")])})],2)]}}],null,true)})],1)]),(_vm.dialogUsuario)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","value":true},on:{"input":function($event){_vm.dialogUsuario = false}}},[_c('v-card',[_c('v-container',[_c('div',{staticClass:"w-full mx-auto my-auto"},[_c('UsuarioActualizar',{attrs:{"titulo":"Editar datos de usuario","send_usuario":_vm.send_usuario},on:{"actualizar_datos_usuario":_vm.return_usuario}})],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }