var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_usuario.group_datos_basicos.json_filtros.json_filtros,"sort-by":"tx_razon_social"},scopedSlots:_vm._u([{key:"item.json_roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.json_roles.tx_nombre_perfil)+" ")]}},{key:"item.json_nivel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.json_nivel.tx_nivel)+" ")]}},{key:"item.json_grados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.json_grados.tx_nombre_grado)+" ")]}},{key:"item.json_areas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.json_areas.tx_descripcion)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Aplicar Filtros ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('div',[_c('validation-provider',{attrs:{"name":"filtros_temp.json_institucion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil,"item-text":"tx_razon_social","item-value":"nu_id_institucion","placeholder":"Institución","error-messages":errors,"label":"Institución","outlined":"","return-object":"","disabled":!_vm.fields_usuario.isEditing},on:{"change":_vm.setSelectedInstitucion},model:{value:(_vm.filtros_temp.json_institucion),callback:function ($$v) {_vm.$set(_vm.filtros_temp, "json_institucion", $$v)},expression:"filtros_temp.json_institucion"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"filtros_temp.json_roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_institucion_perfiles,"item-text":"tx_desc_perfil","item-value":"nu_id_perfil","placeholder":"Rol","error-messages":errors,"label":"Rol","outlined":"","return-object":"","disabled":!_vm.fields_usuario.isEditing},on:{"change":_vm.setSelectedNivel},model:{value:(_vm.filtros_temp.json_roles),callback:function ($$v) {_vm.$set(_vm.filtros_temp, "json_roles", $$v)},expression:"filtros_temp.json_roles"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"filtros_temp.json_nivel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_institucion_niveles,"item-text":"tx_nivel","loading":_vm.isLoading,"item-value":"nu_id_nivel_educativo","placeholder":"Nivel educativo","error-messages":errors,"label":"Nivel educativo","outlined":"","return-object":"","disabled":!_vm.fields_usuario.isEditing},on:{"change":_vm.setSelectedGrados},model:{value:(_vm.filtros_temp.json_nivel),callback:function ($$v) {_vm.$set(_vm.filtros_temp, "json_nivel", $$v)},expression:"filtros_temp.json_nivel"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"filtros_temp.json_grados","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_institucion_nivel_grados,"item-text":"tx_nombre_grado","item-value":"nu_id_grado","placeholder":"Nivel/Grado/Año","error-messages":errors,"label":"Nivel/Grado/Año","outlined":"","return-object":"","disabled":!_vm.fields_usuario.isEditing},on:{"change":_vm.setSelectedArea},model:{value:(_vm.filtros_temp.json_grados),callback:function ($$v) {_vm.$set(_vm.filtros_temp, "json_grados", $$v)},expression:"filtros_temp.json_grados"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"filtros_temp.json_areas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_institucion_areas,"loading":_vm.isLoading,"item-text":"tx_descripcion","item-value":"nu_id_area_formacion","placeholder":"Área de formación","error-messages":errors,"label":"Área de formación","outlined":"","return-object":"","disabled":!_vm.fields_usuario.isEditing},on:{"change":_vm.setSelected},model:{value:(_vm.filtros_temp.json_areas),callback:function ($$v) {_vm.$set(_vm.filtros_temp, "json_areas", $$v)},expression:"filtros_temp.json_areas"}})]}}])})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Esta seguro de borrar este registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Si")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }