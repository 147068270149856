<template>
  <div class="">
        <div class="">
            <div>
                <v-data-table
                  :headers="headers"
                  :items="fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >
                
                  <template v-slot:item.json_perfil="{ item }">
                      <div v-for="(item_esp, index) in item.json_perfil" :key="index">

                      {{ item_esp.tx_nombre_perfil }}
                      </div>
                  </template>     
                  <!--                   
                  <template v-slot:item.json_institucion_perfil="{ item }">
                      {{ item.json_institucion_perfil.tx_razon_social }}
                  </template>      -->
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Asociar Usuario a Perfil
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="institucion_perfil.json_institucion_perfil"
                                          :rules=fields_usuario.group_datos_basicos.json_institucion_perfil.caracteristicas.required
                                        >
                                                <v-select
                                                  v-model="institucion_perfil.json_institucion_perfil"
                                                  :items="fields_usuario.group_datos_basicos.json_institucion.json_institucion"
                                                  item-text="tx_razon_social"
                                                  item-value="nu_id_institucion"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_usuario.group_datos_basicos.json_institucion_perfil.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_usuario.group_datos_basicos.json_institucion_perfil.caracteristicas.label
                                                  outlined
                                                  return-object
                                                  :disabled=!fields_usuario.isEditing
                                                ></v-select>                      

                                        </validation-provider>                    

                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >                  

                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="institucion_perfil.json_perfil"
                                              :rules=fields_usuario.group_datos_basicos.json_perfil.caracteristicas.required
                                          >
                                              <v-autocomplete
                                              v-model="institucion_perfil.json_perfil"
                                              :items="list_perfiles"
                                              :error-messages="errors"
                                              chips
                                              outlined
                                              color="blue-grey lighten-2"
                                              :label=fields_usuario.group_datos_basicos.json_perfil.caracteristicas.label
                                              item-text="tx_nombre_perfil"
                                              item-value="nu_id_perfil"
                                              multiple
                                              return-object
                                              :disabled=!fields_usuario.isEditing
                                              >
                                              <template v-slot:selection="data">
                                                  <v-chip
                                                  v-bind="data.attrs"
                                                  :input-value="data.selected"
                                                  close
                                                  @click="data.select"
                                                  @click:close="remove(data.item)"
                                                  >
                                                  {{ data.item.tx_nombre_perfil }}
                                                  </v-chip>
                                              </template>

                                              </v-autocomplete>


                                          </validation-provider>

                                      </v-col>

                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_PERFIL } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'UsuarioPerfilRegistrar',
  data () {
    return {
        list_perfiles: [],
        fields_usuario_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_institucion_perfil: '',
          json_perfil: '',
        },  
        defaultItem: {
          json_institucion_perfil: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'json_institucion_perfil',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   
        institucion_perfil: {
          json_institucion_perfil: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'json_institucion_perfil',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   

        headers: [
            {
              text: 'Institución',
              align: 'start',
              sortable: false,
              value: 'tx_razon_social',
            },
            { text: 'Perfiles', value: 'json_perfil' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_usuario','fields_usuario']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  props: {
    send_usuario: Object
  },
  components: {

  },    
  methods: {
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.institucion_perfil.json_perfil = []
          this.institucion_perfil.json_institucion = {}
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.json_institucion_perfil = Object.assign({}, this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil[this.editedIndex], this.institucion_perfil)
        } else {
          console.log('this.editedItem: ', this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil)
          /*var aa = {
            json_institucion_perfil: this.institucion_perfil.json_institucion_perfil,
            json_perfil: this.institucion_perfil.json_perfil
          }
          */
          //this.fields_usuario.json_institucion_perfil.json_perfil = this.institucion_perfil.json_perfil
          this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil.push({            
            nu_id_institucion: this.institucion_perfil.json_institucion_perfil.nu_id_institucion,
            tx_razon_social: this.institucion_perfil.json_institucion_perfil.tx_razon_social,
            json_perfil: this.institucion_perfil.json_perfil
          })
          //this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil.push(this.institucion_perfil.json_institucion_perfil)
        }
        this.close()
      },    
      initialize () {
        this.institucion_perfil = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil.indexOf(item)
        this.institucion_perfil = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        console.log('deleteItem: ', item.json_institucion_perfil.nu_id_centro_medico)
        this.editedIndex = this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchPerfiles(q) {
      this.$store.dispatch(LIST_PERFIL, {q: q}).then(response => {
          console.log('searchLIST_PERFIL: ', response )
          this.list_perfiles = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
    remove (item) {
      var index = this.institucion_perfil.json_perfil.findIndex(i => i.nu_id_perfil === item.nu_id_perfil);
      if (index >= 0) this.institucion_perfil.json_perfil.splice(index, 1)
    },    

  },
  
  mounted() {
    this.searchPerfiles();
    if (this.send_usuario.operacion === 'EDITAR') {
      //console.log('json_institucion_perfil: ', JSON.parse(this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil))
        //this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil = JSON.parse(this.send_usuario.usuario.json_institucion_perfil)
        //this.fields_usuario.group_datos_basicos.json_perfil.json_perfil = JSON.parse(this.send_usuario.usuario.json_perfil)
          // for (var prop in this.send_usuario.usuario) {
          //     if(typeof this.fields_usuario[prop] === 'object') {
          //           if (this.fields_usuario[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_usuario[prop][prop] = JSON.parse(this.send_usuario.usuario[prop])
          //           } else {
          //             this.fields_usuario[prop][prop] = this.send_usuario.usuario[prop]
          //           }
          //     } else {
          //           this.fields_usuario[prop][prop] = this.send_usuario.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        this.fields_usuario.group_datos_basicos.json_perfil.json_perfil = []
        this.fields_usuario.isEditing = true
    }           
  },
    
}
</script>