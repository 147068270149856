<template>
    <div>
        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="usuario"
                    :items-per-page="5"
                    :expanded.sync="expanded"   
                    item-key="nu_id_usuario"
                    show-expand                                     
                    :search="search"        
                    class="elevation-1"
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="registrar_consulta(item)"
                        >
                          {{ icons.mdiBookPlusMultiple }}
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                        <!-- name -->
                        <template #[`item.tx_nombres`]="{item}">
                          <div class="d-flex flex-column">
                            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_nombres }}</span>
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>          
                        <template #[`item.tx_apellidos`]="{item}">
                          <div class="d-flex flex-column">
                            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_apellidos }}</span>
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>    
                        <template #[`item.nu_sexo`]="{item}">
                          <div  class="d-flex flex-column">
                            <span v-if="item.nu_sexo === 1" class="d-block font-weight-semibold text--primary text-truncate">Femenino</span>
                            <span v-else class="d-block font-weight-semibold text--primary text-truncate">Masculino</span>
                          </div>
                        </template>      
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                              <span class="font-bold">Pertiles</span>
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_institucion_perfil)" :key="index">      
                                {{ item_01.tx_nombre_perfil }}
                              </div>                            
                          </td>
                        </template>                                                  
                  </v-data-table>
              </div>
    </div>

        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogUsuario" :value="true" @input="dialogUsuario = false"                   
            >
            <v-card>
              <v-container>

                <!--<div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Historia clínica: </span><span><strong>{{ fields_usuario.group_usuario.tx_usuario.tx_usuario }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_usuario.group_usuario.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                
-->
                <div class="w-full mx-auto my-auto">

                    <UsuarioActualizar titulo="Editar datos de usuario" @actualizar_datos_usuario="return_usuario" :send_usuario="send_usuario"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
import { LIST_USUARIO } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import UsuarioActualizar from "@/views/configuracion/Seguridad/Usuario/UsuarioActualizar.vue";

export default {
    name: 'UsuarioListar',
    data() {
        return {
            dialogUsuario: false, 
            send_usuario: {},
            search: '',
            titulo: 'Listar Usuarios',
            usuario_01: [],
            expanded: [],            
            headers: [
              {
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'nu_id_usuario',
              },          
              {
                text: 'Usuario',
                sortable: true,
                value: 'tx_username',
              },          
              { 
                text: 'Correo electrónico', 
                value: 'tx_correo_usuario', 
                sortable: false,

              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },
              { 
                text: '', 
                value: 'data-table-expand' 
              },                        
            ],            
        }
    },
    props: {
        usuario: []

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_usuario.usuario = id
      this.send_usuario.operacion = 'EDITAR'
      this.dialogUsuario = true
    },
    return_usuario(msg) {    
        this.$emit("actualizar_datos_usuario", msg);
        this.searchUsuario('')
        this.dialogUsuario = false
    },    
    searchUsuario(q) {
      this.$store.dispatch(LIST_USUARIO, {nu_id_usuario: q}).then(response => {
          this.usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
  
    }, 
    components: {
      UsuarioActualizar
    },
    mounted() {
      //this.searchUsuario({})
      //this.usuario_01 = this.usuario; 
      //console.log('Usuarios: ', this.usuario)
    },
    watch: {
    },    


}
</script>