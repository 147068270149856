<template>
  <div class="">
        <div class="">
            <div>         
                <v-data-table
                  :headers="headers"
                  :items="fields_usuario.group_datos_basicos.json_filtros.json_filtros"
                  sort-by="tx_razon_social"
                  class="elevation-1 mb-10"

                >
  
                  <template v-slot:item.json_roles="{ item }">
                      {{ item.json_roles.tx_nombre_perfil }}
                  </template>     
                  <template v-slot:item.json_nivel="{ item }">
                      {{ item.json_nivel.tx_nivel }}
                  </template>     
                  <template v-slot:item.json_grados="{ item }">
                      {{ item.json_grados.tx_nombre_grado }}
                  </template>     
                  <template v-slot:item.json_areas="{ item }">
                      {{ item.json_areas.tx_descripcion }}

<!--                       <div v-for="(item_area, index) in item.json_areas" :key="index">

                            {{ item.item_area.tx_descripcion }}
                      </div>
 -->                  </template>     

                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Aplicar Filtros
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="filtros_temp.json_institucion"
                                            rules='required'
                                          >
                                                  <v-select
                                                    v-model="filtros_temp.json_institucion"
                                                    :items="fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil"
                                                    item-text="tx_razon_social"
                                                    item-value="nu_id_institucion"
                                                    class="input-group--focused text-lg"
                                                    placeholder='Institución'
                                                    :error-messages="errors"
                                                    label='Institución'
                                                    outlined
                                                    return-object
                                                    @change="setSelectedInstitucion"
                                                    :disabled=!fields_usuario.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div> 
                                        <!-- Telefono --> 
                                        <div>

                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  name="filtros_temp.json_roles"
                                                  rules='required'
                                                >
                                                  <v-select
                                                    v-model="filtros_temp.json_roles"
                                                    :items="list_institucion_perfiles"                                  
                                                    item-text="tx_desc_perfil"
                                                    item-value="nu_id_perfil"
                                                    class="input-group--focused text-lg"
                                                    placeholder='Rol'
                                                    :error-messages="errors"
                                                    label='Rol'
                                                    outlined
                                                    return-object                                                    
                                                    @change="setSelectedNivel"                                                    
                                                    :disabled=!fields_usuario.isEditing
                                                  ></v-select>                      
                                                </validation-provider>     
                                        </div>
                                        <div>

                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  name="filtros_temp.json_nivel"
                                                  rules='required'
                                                >
                                                  <v-select
                                                    v-model="filtros_temp.json_nivel"
                                                    :items="list_institucion_niveles"                                  
                                                    item-text="tx_nivel"
                                                    :loading="isLoading"
                                                    item-value="nu_id_nivel_educativo"
                                                    class="input-group--focused text-lg"
                                                    placeholder='Nivel educativo'
                                                    :error-messages="errors"
                                                    label='Nivel educativo'
                                                    outlined
                                                    return-object                                                    
                                                    @change="setSelectedGrados"
                                                    :disabled=!fields_usuario.isEditing
                                                  ></v-select>                      
                                                </validation-provider>     
                                        </div>
                                        <div>

                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  name="filtros_temp.json_grados"
                                                  rules='required'
                                                >
                                                  <v-select
                                                    v-model="filtros_temp.json_grados"
                                                    :items="list_institucion_nivel_grados" 
                                                    item-text="tx_nombre_grado"
                                                    item-value="nu_id_grado"
                                                    class="input-group--focused text-lg"
                                                    placeholder='Nivel/Grado/Año'
                                                    :error-messages="errors"
                                                    label='Nivel/Grado/Año'
                                                    outlined
                                                    return-object                                                                                                        
                                                    @change="setSelectedArea"
                                                    :disabled=!fields_usuario.isEditing
                                                  ></v-select>                      
                                                </validation-provider>     
                                        </div>
                                        <div>

                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  name="filtros_temp.json_areas"
                                                  rules='required'
                                                >
                                                  <v-select
                                                    v-model="filtros_temp.json_areas"
                                                    :items="list_institucion_areas"                                  
                                                    :loading="isLoading"                                 
                                                    item-text="tx_descripcion"
                                                    item-value="nu_id_area_formacion"
                                                    class="input-group--focused text-lg"
                                                    placeholder='Área de formación'
                                                    :error-messages="errors"
                                                    label='Área de formación'
                                                    outlined
                                                    return-object                                                                                                        
                                                    @change="setSelected"
                                                    :disabled=!fields_usuario.isEditing
                                                  ></v-select>                      
                                                </validation-provider>     
                                        </div>                                              

                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro de borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { LIST_INSTITUCION_NIVELES, LIST_PLAN_AREAS, LIST_INSTITUCION_NIVEL_PLAN_GRADOS } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'UsuarioInstitucionRegistrar',
  data () {
    return {
        muestra: true,
        list_institucion_niveles: [],
        list_institucion_perfiles: [],
        list_institucion_nivel_grados: [],
        list_institucion_areas: [],
        list_red_social: [
          {
            co_tipo: 'instagram',
            tx_descripcion: 'Instagram'
          },
          {
            co_tipo: 'twitter',
            tx_descripcion: 'Twitter'
          },
          {
            co_tipo: 'tiktok',
            tx_descripcion: 'Tiktok'
          },
        ],
        list_tipo_contacto: [
          {
            co_tipo: 'telefono',
            tx_descripcion: 'Teléfono'
          },
          {
            co_tipo: 'correo',
            tx_descripcion: 'Correo electrónico'
          },
          {
            co_tipo: 'redes',
            tx_descripcion: 'Redes sociales'
          },
        ],
        list_tipo_telefono: [
          {
            co_tipo: 'local',
            tx_descripcion: 'Local'
          },
          {
            co_tipo: 'celular',
            tx_descripcion: 'Celular'
          },
          {
            co_tipo: 'otro',
            tx_descripcion: 'Otros'
          },
        ],
        list_correo: [
          {
            co_tipo: 'correo',
            tx_descripcion: 'Correo'
          },
        ],
        filtros_temp: {
          json_institucion: [],
          json_roles: [],
          json_nivel: [],
          json_grados: [],
          json_areas: [],
          json_seccion: ''
        },
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_institucion: '',
          json_filtros: '',
        },  
        defaultItem: {
          json_institucion: [],
          json_filtros: [],
          caracteristicas: {
            nombre: 'json_institucion',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        institucion_especialidad: {},
        items_data_table: [],   
        items_niveles: {
          json_ano_nivel: [],
          co_tipo: '',
          tx_etiqueta: '',
          tx_descripcion: '',
          nu_id_padre: '',
          json_nivel_padre: [],
        },   

        headers: [
            {
              text: 'Institución',
              align: 'start',
              sortable: false,
              value: 'tx_razon_social',
            },
            { text: 'Rol', value: 'json_roles' },        
            { text: 'Nivel educativo', value: 'json_nivel' },        
            { text: 'Nivel/Grado/Año', value: 'json_grados' },        
            { text: 'Área de Formación', value: 'json_areas' },        
            { text: 'Acción', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_usuario']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },

    medio () {
      if (this.filtros_temp.json_institucion === 'telefono') {
          return this.list_tipo_telefono
      } else if (this.filtros_temp.json_institucion === 'correo') {
          return this.list_correo
      }      
      return this.list_red_social
    },

  },
  props: {
    send_usuario: Object
  },
  components: {

  },    
  methods: {
      datos_muestra() {
        return this.medio
      },      
      setSelectedNivel(value) {
        //  trigger a mutation, or dispatch an action  
        console.log('Valores setSelectedNivel   : ', this.filtros_temp.json_institucion.nu_id_institucion)
        this.searchInstitucionNiveles(this.filtros_temp.json_institucion.nu_id_institucion, value)
        //this.list_plan_areas = value.json_area       
      },    
      setSelectedGrados(value) {
        //  trigger a mutation, or dispatch an action
                this.searchInstitucionGrados(value)

        
      },    
      setSelectedArea(value) {
        //  trigger a mutation, or dispatch an action  
        console.log('Valores setSelectedArea   : ', value)
        
        this.searchInstitucionArea(value)

      },    
      setSelectedInstitucion(value) {
        //  trigger a mutation, or dispatch an action  
        console.log('Valores setSelectedInstitucion  : ',value)
        this.list_institucion_perfiles = value.json_perfil       
      },    
      setSelected(value) {
        //  trigger a mutation, or dispatch an action  
        console.log('Valores   : ',value)
        if (value === 'correo') {
          this.muestra = false;
          this.filtros_temp.json_roles = 'correo'
        } else {
          this.muestra = true;
        }
        //this.list_plan_areas = value.json_area       
      },    
      close () {
        this.dialog = false
        this.$nextTick(() => {
          //this.cleanig()      
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {
          console.log('Save: ', this.filtros_temp)
          /*var aa = {
            json_institucion: this.institucion_especialidad.json_institucion,
            json_filtros: this.institucion_especialidad.json_filtros
          }
          */
          //this.fields_usuario.json_institucion.json_filtros = this.institucion_especialidad.json_filtros
          for (const property in this.filtros_temp) {
              console.log('item: ', property, this.filtros_temp[property])
          }
          
          //this.fields_usuario.group_datos_basicos.json_filtros.json_filtros.push(this.filtros_temp)
          
          this.fields_usuario.group_datos_basicos.json_filtros.json_filtros.push({
            nu_id_institucion: this.filtros_temp.json_institucion.nu_id_institucion,
            tx_razon_social: this.filtros_temp.json_institucion.tx_razon_social,
            json_roles: this.filtros_temp.json_roles,
            json_nivel: this.filtros_temp.json_nivel,
            json_grados: this.filtros_temp.json_grados,
            json_areas: this.filtros_temp.json_areas,
          })
 
              console.log('Campos: ',  this.fields_usuario.group_datos_basicos.json_filtros.json_filtros)
          //this.fields_usuario.group_niveles.json_ano_nivel.json_ano_nivel = this.items_data_table

          //this.fields_usuario.group_niveles.json_institucion.json_institucion.push(this.institucion_especialidad.json_institucion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        //this.searchInstitucion(item.json_institucion.nu_id_institucion)
        //this.editedIndex = this.fields_usuario.group_niveles.json_ano_nivel.json_ano_nivel.indexOf(item)
        const claves_primarias = {}
        claves_primarias.tx_nombre = 'tx_nombre'
             console.log('item json_areas json_filtros: ', this.fields_usuario.group_datos_basicos.json_filtros.json_filtros)
        // for (const property in item) {
        //     if (claves_primarias[property] != property) {
        //         this.filtros_temp[property] = item[property]
        //     }               
        // }
        // //this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil =[item['json_institucion']]
        
        this.filtros_temp.json_institucion = {
          nu_id_institucion: item.nu_id_institucion,
          tx_razon_social: item.tx_razon_social,
        }
        for (const property in this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil) {
          this.list_institucion_perfiles = this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil[property].json_perfil
        }
 
        this.searchInstitucionNiveles(this.filtros_temp.json_institucion.nu_id_institucion)
        this.filtros_temp.json_nivel = item.json_nivel
        this.setSelectedGrados(this.filtros_temp.json_nivel)
        this.filtros_temp.json_grados = item.json_grados
        this.setSelectedArea(this.filtros_temp.json_grados)
        this.filtros_temp.json_areas = item.json_areas
        
        
        this.filtros_temp.json_roles =   item.json_roles          

        console.log('filtros_temp.json_institucion: ', this.filtros_temp.json_institucion)
        //this.setSelectedInstitucion(this.filtros_temp.json_institucion)
        //this.searchInstitucionNiveles(this.filtros_temp.json_institucion.json_institucion_perfil.nu_id_institucion)
            
        this.dialog = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_ano_nivel = 'json_ano_nivel'

        for (const property in this.fields_usuario.group_datos_basicos) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_usuario.group_datos_basicos[property][property] = []
              } else {
                this.fields_usuario.group_datos_basicos[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        this.editedIndex = this.fields_usuario.group_datos_basicos.json_filtros.json_filtros.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_usuario.group_datos_basicos.json_filtros.json_filtros.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      
    searchInstitucionNiveles(q) {
      this.isLoading = true
      this.$store.dispatch(LIST_INSTITUCION_NIVELES, {nu_id_institucion: q}).then(response => {
          console.log('LIST_INSTITUCION_NIVELES 2: ', response.rest.items)
          this.list_institucion_niveles = response.rest.items
          this.list_institucion_niveles.push({nu_id_nivel_educativo: '99999', tx_nivel: 'Todos',json_grados: JSON.stringify({
            nu_id_grado: 99999,
            tx_nombre_grado: 'Todos'
          })})
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      }).finally(() => (
        setTimeout(() => {
            this.isLoading = false
          },3000)               
        //this.e1 = 1
        //this.limpiar_campos_clientes()
        ))       
    },
    searchInstitucionGrados(params) {

      var list_grados = []
      list_grados.push(
          {
            nu_id_nivel_educativo: params.nu_id_nivel_educativo, 
            nu_id_plan: params.nu_id_plan, 
            nu_id_ano_escolar: params.nu_id_ano_escolar,
            nu_id_grado: '99999',
            tx_nombre_grado: 'Todos',
            })


        //this.list_institucion_nivel_grados.push(JSON.parse(value.json_grados))        
        this.list_institucion_nivel_grados = list_grados        

      this.isLoading = true
      this.$store.dispatch(LIST_INSTITUCION_NIVEL_PLAN_GRADOS, params ).then(response => {
          this.list_institucion_nivel_grados = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      }).finally(() => (
        setTimeout(() => {
            this.isLoading = false
          },3000)               
        //this.e1 = 1
        //this.limpiar_campos_clientes()
        ))       
    },           
    searchInstitucionArea(q) {

        var list_areas = []
        //list_areas = JSON.parse(q.json_grados)
        list_areas.push(
            {
              nu_id_nivel_educativo: q.nu_id_nivel_educativo, 
              nu_id_plan: q.nu_id_plan, 
              nu_id_ano_escolar: q.nu_id_ano_escolar,
              nu_id_grado: '99999',
              tx_nombre_grado: 'Todos',
              nu_id_area_formacion: '99999',
              tx_descripcion: 'Todos',
              })


      var params = {
        json_grados: q,
      }
      this.isLoading = true
      this.$store.dispatch(LIST_PLAN_AREAS, params ).then(response => {
          console.log('LIST_PLAN_AREAS 2: ', response.rest.items)
          list_areas.push(response.rest.items)
          this.list_institucion_areas = response.rest.items

      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      }).finally(() => (
        setTimeout(() => {
            this.isLoading = false
          },3000)               
        //this.e1 = 1
        //this.limpiar_campos_clientes()
        ))       
    },      
    remove (item) {
      var index = this.institucion_especialidad.json_filtros.findIndex(i => i.nu_id_especialidad === item.nu_id_especialidad);
      if (index >= 0) this.institucion_especialidad.json_filtros.splice(index, 1)
    },    

  },
  
  mounted() {
    //this.searchPerfiles();
    //this.searchInstitucion()
        this.fields_usuario.isEditing = true
    if (this.send_usuario.operacion === 'EDITAR') {
              

            // json_roles: this.filtros_temp.json_roles = this.fields_usuario.group_datos_basicos.json_filtros.json_filtros
            // json_nivel: this.filtros_temp.json_nivel,
            // json_grados: this.filtros_temp.json_grados,
            // json_areas: this.filtros_temp.json_areas,      
             //console.log('Usuario Institucon Filtros: ', this.fields_usuario.group_datos_basicos.json_filtros.json_filtros[prop].json_roles)

        /*for (var prop in this.fields_usuario.group_datos_basicos.json_filtros.json_filtros) {

            this.filtros_temp.json_institucion.json_institucion_perfil.push(this.fields_usuario.group_datos_basicos.json_filtros.json_filtros[prop]) 
            //this.filtros_temp.json_institucion.json_perfil.push(this.fields_usuario.group_datos_basicos.json_filtros.json_filtros[prop].json_roles)

        }
*/
        //this.fields_usuario.group_niveles.json_institucion.json_institucion = JSON.parse(this.send_usuario.usuario.json_institucion)
        //this.fields_usuario.group_niveles.json_filtros.json_filtros = JSON.parse(this.send_usuario.usuario.json_filtros)
          // for (var prop in this.send_usuario.usuario) {
          //     if(typeof this.fields_usuario[prop] === 'object') {
          //           if (this.fields_usuario[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_usuario[prop][prop] = JSON.parse(this.send_usuario.usuario[prop])
          //           } else {
          //             this.fields_usuario[prop][prop] = this.send_usuario.usuario[prop]
          //           }
          //     } else {
          //           this.fields_usuario[prop][prop] = this.send_usuario.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.list_institucion_niveles = JSON.parse(this.fields_usuario.group_niveles.json_institucion.json_institucion[0].json_filtros)
        //this.fields_usuario.group_niveles.json_ano_nivel.json_ano_nivel = []
        this.fields_usuario.isEditing = true
    }           
  },
    
}
</script>