<template>
    <div class="text-skin-base w-full">
                <div class="md:flex  justify-end md:justify-start w-full">                  
                    <div class="my-auto p-2 md:pl-10 w-full md:w-1/5">
                        <button @click="registrar_perfil()" class="w-full font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-4 mt-1 m-1">
                            Crear perfil
                        </button>                
                    </div>
<!--                            @change="verificar_frecuencia()"                        
-->        
                </div>


        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogPerfil" :value="true" @input="dialogPerfil = false"                   
            >
            <v-card>
              <v-container>
                <!--<div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Historia clínica: </span><span><strong>{{ fields_perfil.group_perfil.tx_perfil.tx_perfil }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_perfil.group_perfil.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                
-->
                <div class="w-full mx-auto my-auto">

                    <PerfilActualizar titulo="Registrar Perfil" @actualizar_datos_perfil="return_perfil" :send_perfil="send_perfil"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
import {   LIST_PERFIL } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import PerfilActualizar from "@/components/configuracion/PerfilActualizar.vue";

export default {
    name: 'PerfilOpciones',
    data() {
        return {
            dialogPerfil: false,
            buscarPaciente: false, 
            send_perfil: {},
            send_motivo: {},
            datos_perfil: [],
            isLoading: false,
        }
    },
    props: {
        titulo: String

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
        searchPerfil(q) {
          console.log('Paciente: ', q)
          this.$store.dispatch(LIST_PERFIL, {nu_id_perfil: q}).then(response => {
              this.consultasmedica = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },  
        registrar_perfil () {
            this.dialogPerfil = true
            this.send_perfil.operacion = 'REGISTRAR'
            this.fields_perfil.isEditing = true
            // console.log(value) // someValue
        },
        return_perfil(msg) { 
   
            this.$emit("actualizar_datos_perfil", msg);
            this.dialogPerfil = false
        },    

    },
    computed: {
        ...mapGetters(['fields_perfil','fields_persona','currentUser']),
    },
    components: {
        PerfilActualizar,
    },
    mounted() {
        this.searchPerfil()
    },     
}
</script>