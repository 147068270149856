 <template>
  <div class="w-full p-2">

      <div>
        <UsuarioOpciones @actualizar_datos_usuario="return_usuario" :titulo="titulo"/>
      </div>
      <div>
        <UsuarioListar :usuario="datos_usuario"></UsuarioListar>
      </div>

  </div>
</template>

<script>
import UsuarioOpciones from "@/views/configuracion/Seguridad/Usuario/UsuarioOpciones.vue";
import UsuarioListar from "@/views/configuracion/Seguridad/Usuario/UsuarioListar.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { LIST_USUARIO } from '@/store/actions.type'

export default {
  name: 'Usuario',
  data () {
    return {
      titulo: 'Usuario',
      datos_usuario: [],
      send_usuario: {},

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    UsuarioOpciones,
    UsuarioListar
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    searchUsuario(q) {
      this.$store.dispatch(LIST_USUARIO, {q: q}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_usuario(msg) {
      this.searchUsuario({q: msg})

      //this.send_usuario = msg;
    },      
  },
  mounted () {
    this.searchUsuario({})
    /*  this.$store.dispatch(READ_USUARIO, {}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
