<template>
  <div class="p-2">
        <ValidationObserver ref="datos_basicos">
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_perfil.group_datos_basicos.tx_nombre_perfil.tx_nombre_perfil"
                :rules=fields_perfil.group_datos_basicos.tx_nombre_perfil.caracteristicas.required
              >
                <v-text-field
                  v-model="fields_perfil.group_datos_basicos.tx_nombre_perfil.tx_nombre_perfil"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_perfil.group_datos_basicos.tx_nombre_perfil.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_perfil.isEditing                            
                ></v-text-field>
              </validation-provider>     
                </template>
                <span>{{fields_perfil.group_datos_basicos.tx_nombre_perfil.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>

          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_perfil.group_datos_basicos.tx_desc_perfil.tx_desc_perfil"
                :rules=fields_perfil.group_datos_basicos.tx_desc_perfil.caracteristicas.required
              >
                <v-textarea
                  v-model="fields_perfil.group_datos_basicos.tx_desc_perfil.tx_desc_perfil"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_perfil.group_datos_basicos.tx_desc_perfil.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_perfil.isEditing                            
                ></v-textarea>
              </validation-provider>     
                </template>
                <span>{{fields_perfil.group_datos_basicos.tx_desc_perfil.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>
          <div>
            <validation-provider
                v-slot="{ errors }"
                name="fields_perfil.group_datos_basicos.json_opcion.json_opcion"
                :rules=fields_perfil.group_datos_basicos.json_opcion.caracteristicas.required
              >
                <label>
                  {{ fields_perfil.group_datos_basicos.json_opcion.caracteristicas.label }}
                </label>
                <treeselect
                  v-model="fields_perfil.group_datos_basicos.json_opcion.json_opcion"
                  :multiple="true" 
                  :options="roles"
                  value-consists-of="ALL_WITH_INDETERMINATE"
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_perfil.group_datos_basicos.json_opcion.caracteristicas.label
                  :disabled=!fields_perfil.isEditing                            
                ></treeselect>
              </validation-provider>     

          </div>



                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>Seguridad
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

          </ValidationObserver>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_ROLES  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'PerfilDatosBasicos',
  data () {
    return {
        modal: false,                 
        search:null,
        isLoading: false,
        perfiles: {},
        roles: [],  
        roles_1: [],      
        d_nacionalidad: [
            { value: 'V', text: 'Venezolana' },
            { value: 'E', text: 'Extranjera', 
              disabled: false 
            }
          ],
        d_sexo: [
                  { value: 1, text: 'Femenino' },
                  { value: 2, text: 'Masculino', 
                    disabled: false 
                  }
                ],                        
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
 
  },     
  computed: {
    ...mapGetters(['fields_perfil'])    
  },
  props: {
    send_perfil: Object
  },
  components: {
    Treeselect

  },    
  methods: {
    searchRoles() {
      this.$store.dispatch(LIST_ROLES, {}).then(response => {
          console.log('(MUESTRA_ROLES', response.rest.datos)
          //this.roles = Object.assign({}, response.rest.datos.items)
          this.roles = response.rest.datos.items

      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      

    },  
    async guardar () {
        const isValid = await this.$refs.datos_basicos.validate()
        if (!isValid) {
            return true
        }
        this.fields_perfil.group_datos_basicos.json_opcion.json_opcion= JSON.stringify(this.fields_perfil.group_datos_basicos.json_opcion.json_opcion)
        this.$emit("guardar_perfil_datos_basicos", JSON.stringify(this.fields_perfil.group_datos_basicos));
    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

    this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          




  },
  
  mounted() {
      this.searchRoles()
      //this.fields_perfil.group_datos_basicos.json_opcion.json_opcion = '1,2,3';
      //this.roles_1 = this.fields_perfil.group_datos_basicos.json_opcion.json_opcion.split(",");

      //var strin = "[77 78]";
      //this.roles_1 = Object.assign([], strin);Array.from(); 
      //this.roles_1 =  Array.from(strin);

  },
    
}
</script>