 <template>
  <div class="w-full p-2">
      <div>
        <UsuarioPerfilOpciones @guardar_usuario_perfil_datos_basicos="return_usuario" :send_usuario="send_usuario" :titulo="titulo"/>
      </div>
      <!--<div>
        <UsuarioPerfilListar :usuario="datos_usuario" :send_usuario="send_usuario"></UsuarioPerfilListar>
      </div>
      -->

  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import UsuarioPerfilOpciones from "@/views/configuracion/Seguridad/Usuario/UsuarioPerfilOpciones.vue";
//import UsuarioPerfilListar from "@/views/configuracion/UsuarioPerfilListar.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { LIST_USUARIO, UPDATE_USUARIO_TEMP } from '@/store/actions.type'

export default {
  name: 'UsuarioPerfil',
  data () {
    return {
      titulo: 'Usuario Perfil',
      datos_usuario: [],
      mensaje: {
        satisfactorio: {
          status: false,
          texto: ''
        },
        nosatisfactorio: {
          status: false,
          texto: ''
        },
      },

    }
  },
  props: {
      send_usuario: Object,
  },
  setup() {
  },  
  components: {
    UsuarioPerfilOpciones,
    //UsuarioPerfilListar
  //  ThemifyIcon    
  },  
  watch: {

  },  
  computed: {
      ...mapGetters(['fields_usuario']),

  },      
  methods: {
    searchUsuarioPerfil(q) {
      this.$store.dispatch(LIST_USUARIO, q).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_usuario(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.stringify(msg);
            
            console.log('Usuario Perfil: ', JSON.parse(guardar))
            


            const formData = new FormData()
            formData.append('usuario', guardar)
            formData.append('nu_id_usuario', this.fields_usuario.group_datos_basicos.nu_id_usuario.nu_id_usuario)

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              this.$store.dispatch(UPDATE_USUARIO_TEMP, formData).then(datos => {
                guardar.btn_guardar = 'true';
                  this.mensaje.satisfactorio.texto = "Modificación de la institución fue satisfactoria"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
              
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

          this.searchUsuarioPerfil({nu_id_usuario: this.send_usuario.usuario.nu_id_usuario})
          /// hasta aca optimizar

    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_usuario = msg;
        //this.select_usuario = JSON.parse(msg);
        console.log("return_consulta_medica guardar_usuario update: " , guardar_usuario);

        
        if (guardar_usuario.btn_guardar == 'true') {
            this.searchUsuarioPerfil()
            //this.fields.usuario = this.select_usuario.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_usuario.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_usuario.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_usuario = msg;
    },        

  },
  mounted () {
    //this.searchUsuarioPerfil('')
    /*  this.$store.dispatch(READ_USUARIO, {}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
