<template>
<div >
    <div class="">
        <div v-if="titulo">
            <UsuarioHeader :titulo="titulo "  />        
        </div>
      <div>
          <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
            <NotificacionInterna :mensaje="mensaje" />  
            
          </div>
         <v-stepper v-model="e1">
            <v-stepper-header>
                  <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3">
                  </v-stepper-step>
            </v-stepper-header>
            <div class="flex justify-center mt-1 text-lg font-bold"> 
                {{vista[e1 - 1].descripcion }}
            </div>
            <div class="flex justify-center mt-1 text-xs font-bold"> 
                Los campos con el "*" son Obligatorios
            </div>
      <div v-if="send_usuario.operacion === 'EDITAR'" class="flex justify-center items-center" @click="fields_usuario.isEditing = !fields_usuario.isEditing ">
          <div  v-if="fields_usuario.isEditing === true" class="cursor-pointer py-2 px-6 mr-1 w-12 flex justify-center items-center bg-skin-button-muted">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiStopCircleOutline }}
                  </v-icon>
              </div>

              <div class="text-white">
                Cancelar edición
              </div>

          </div>  
          <div v-else   class="cursor-pointer py-2 px-6 mr-1 w-12 flex justify-center items-center bg-skin-button-accent">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiPencil }}
                  </v-icon>
              </div>

              <div class="text-white">
                Editar
              </div>

          </div>
      </div>            
          <v-stepper-items > 
            <div class="">
              <ValidationObserver ref="datos_basicos">
                  <v-stepper-content step="1">  
                    <UsuarioDatosBasicos titulo="" @guardar_usuario_datos_basicos="return_datos_basicos" :send_usuario="send_usuario"  />
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between  pt-2 pb-10">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="onSubmit_datos_basicos(1)" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Siguiente
                                </button>                          
                                <button @click="onSubmit_datos_basicos(1)" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowRightBoldOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    
                  </v-stepper-content>
              </ValidationObserver>
                <ValidationObserver ref="datos_instituciones">
                  <v-stepper-content step="2">
                    <UsuarioPerfil titulo=""  :send_usuario="send_usuario"/>        
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between  pt-2 pb-10">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>    


                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="anterior(2)" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Anterior
                                </button>                          
                                <button @click="anterior(2)" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowLeftBoldOutline }}
                                                </v-icon>
                                </button>                          

                                <button @click="onSubmit_usuario_perfil(2)" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Siguiente
                                </button>                          
                                <button @click="onSubmit_usuario_perfil(2)" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowRightBoldOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    
                  </v-stepper-content>
                </ValidationObserver>
                <ValidationObserver ref="datos_perfiles">
                  <v-stepper-content step="3">

                    <UsuarioInstitucion titulo="" @guardar_usuario_datos_basicos="return_datos_basicos" :send_usuario="send_usuario"  />
                    
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>    


                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="anterior(3)" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Anterior
                                </button>                          
                                <button @click="anterior(3)" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowLeftBoldOutline }}
                                                </v-icon>
                                </button>                          

                                <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                  Procesando...
                                </button>                                                        
                                <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                  Guardar
                                </button>                                                        

                                <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                </button>                                                        
                      

                                <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiContentSave }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    


                  </v-stepper-content>
                </ValidationObserver>


            </div>
          </v-stepper-items> 
        </v-stepper>
      </div>       
    </div> 

</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { CREATE_USUARIO_TEMP } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import UsuarioHeader from "@/components/comunes/HeaderModulo.vue";
import UsuarioDatosBasicos from "@/components/configuracion/Seguridad/Usuario/UsuarioDatosBasicos.vue";
import UsuarioPerfil from "@/views/configuracion/Seguridad/Usuario//UsuarioPerfil";
import UsuarioInstitucion from "@/views/configuracion/Seguridad/Usuario//UsuarioInstitucion";
import NotificacionInterna from "@/components/comunes/NotificacionInterna";


import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus,
  mdiArrowRightBoldOutline,
  mdiArrowLeftBoldOutline,
  mdiStopCircleOutline,
  mdiContentSave,
  mdiLoading

} from '@mdi/js';
import { reactive  } from "@vue/composition-api"

export default {
    name: 'UsuarioTemporalActualizar',
    data() {
        return {
          usuario_temp: {
            fields_usuario: '',
          },
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
            e1: 1,
            isLoading: false,
            mostrar_vista: {
                id: '1',
                descripcion: 'Datos básicos'
                }, 
            vista: [
                {
                id: '1',
                descripcion: 'Datos básicos'
                },
                {
                id: '2',
                descripcion: 'Perfiles'
                },
                {
                id: '3',
                descripcion: 'Filtros'
                },
            ], 
            selected: {
                json_estado_co: [],
                json_municipio_co: [],
                json_parroquia_co: [],
            },            

 //           titulo: "Programar "
        }
    },
    props: {
        send_usuario: Object,
        titulo: String
    },
    components: {
      UsuarioDatosBasicos,
      UsuarioHeader,
      UsuarioPerfil,
      UsuarioInstitucion,
      NotificacionInterna
    },
    computed: {
    ...mapGetters(['fields_usuario']),
        notificar() {
        return this.noti;
        },
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },    
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus,
            mdiArrowRightBoldOutline,
            mdiArrowLeftBoldOutline,
            mdiStopCircleOutline,
            mdiContentSave,
            mdiLoading
        });  


        return {
        icons
        }
    },
    methods: {        
        return_datos_basicos(msg) {
          console.log("return_datos_basicos: ", msg)
            //this.buscarPersona('params')
            //var guardar_datos_basicos = JSON.parse(msg);            
            //this.$emit("actualizar_datos_usuario", JSON.stringify(guardar_datos_basicos));
        },        
        async onSubmit_datos_basicos (step) {
            const isValid = await this.$refs.datos_basicos.validate()
            if (isValid) {
                this.mostrar_vista = this.vista[step]
                this.e1 = this.e1 + 1
            }
        },
        async onSubmit_usuario_perfil (step) {

            var isValid = true
            const instituciones = this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil
            for (const property in instituciones) {
                if (instituciones[property].json_especialidad) {
                  isValid = true
                }
            }
            console.log('isValid: ', isValid)

            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (!isValid) {
              this.mensaje.nosatisfactorio.texto = "Debe asociar al usuario a por lo menos una especialidad"
              this.mensaje.nosatisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        this.mensaje.nosatisfactorio.status = false
                    },3000)                  
                    
              
                return true
            }

            if (isValid) {
                this.mostrar_vista = this.vista[step]
                this.e1 = this.e1 + 1
            }
        },
        anterior(step) {
            this.e1 = step - 1
        },
        async cancelar () {
            this.mensaje.nosatisfactorio.status = false

              this.mensaje.nosatisfactorio.texto = "Operación cancelada"
              this.mensaje.nosatisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                        this.$emit("actualizar_datos_usuario", JSON.stringify(this.fields_usuario));
                        this.mensaje.nosatisfactorio.status = false
                    },3000)                  
        },
        
        async guardar () {
                      
            var isValid = false
            const perfiles = this.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil
            for (const property in perfiles) {
                if (perfiles[property].json_perfil) {
                  isValid = true
                }
            }
            /*
            this.usuario_temp.fields_usuario = this.fields_usuario 
            this.usuario_temp.fields_usuario.group_datos_basicos.json_institucion.json_institucion = JSON.stringify(this.fields_usuario.group_datos_basicos.json_institucion.json_institucion)
            this.usuario_temp.fields_usuario = this.fields_usuario 
            this.usuario_temp.fields_usuario = this.fields_usuario 
            */
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (!isValid) {
              this.mensaje.nosatisfactorio.texto = "Debe asociar al usuario a por lo menos un perfil"
              this.mensaje.nosatisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        this.mensaje.nosatisfactorio.status = false
                    },3000)                  
                    
              
                return true
            } else {
              const formData = new FormData()

               formData.append('usuario_temp', JSON.stringify(this.fields_usuario))

              this.$store.dispatch(CREATE_USUARIO_TEMP, formData).then(response => { 
                console.log("response: ", response )         
                  //window.location = window.location.origin + window.location.pathname
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        this.mensaje.satisfactorio.status = false
                        this.$emit("actualizar_datos_usuario", JSON.stringify(this.usuario_temp));
                    },3000)                  

              }, error => {
                  console.error("Hay un error", error)
                  this.mensaje.nosatisfactorio.texto = "Error agregando al usuario"
                  this.mensaje.nosatisfactorio.status = true
                      
                      setTimeout(() => {
                            this.isLoading = false
                        //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                            this.mensaje.nosatisfactorio.status = false
                        },3000)                  
                        
      

                  this.$store.commit(SET_ERROR, 'Error al Ingresar, validar su usuario o clave')
              })      


              this.mensaje.satisfactorio.texto = "Operación satisfactoria"
              this.mensaje.satisfactorio.status = true
                  
                    
              

            }
           // this.isLoading = true

            
// desde

// hasta
            this.e1 =  1


        }, 
        async limpiar () {
            for (const property in this.fields_usuario) {

                console.log('property: ', property)
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_usuario[property]) {
                          this.fields_usuario[property][property_01][property_01] = ''
                      }
                  }
            }

        },
              
    },
    mounted() {
        if (this.send_usuario.operacion === 'REGISTRAR') {
          //this.limpiar()
          this.fields_usuario.isEditing = true
        } else {
          this.fields_usuario.isEditing = false
        }
      
    },         

  
}
</script>