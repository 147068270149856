
<template>
    <div class="text-skin-base w-full">
              <div class="w-full mx-auto my-auto">
                <UsuarioInstitucionRegistrar @guardar_usuario_especialidad_datos_basicos="return_usuario_especialidad" :send_usuario="send_usuario" :titulo="titulo"/>
              </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
import UsuarioInstitucionRegistrar from "@/components/configuracion/Seguridad/Usuario/UsuarioInstitucionRegistrar.vue";

export default {
    name: 'UsuarioInstitucionOpciones',
    data() {
        return {
            dialogUsuarioInstitucion: false,
            dialogPaciente: false,
            buscarPaciente: false, 
            datos_paciente: [],
            isLoading: false,
            json_paciente: '',
            titulo: 'Registrar Usuario Especialidad'
        }
    },
    props: {
        send_usuario: Object,

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
        registrar_usuario_especialidad () {
            this.dialogUsuarioInstitucion = true
            this.send_usuario.operacion = 'REGISTRAR'
            this.fields_usuario_especialidad.isEditing = true
            // console.log(value) // someValue
        },

        return_usuario_especialidad(msg) {
            //this.buscarPersona('params')
            var guardar_usuario_especialidad = msg;
            //this.select_paciente = JSON.parse(msg);
            console.log("return_usuario_especialidad guardar_usuario_especialidad: " , guardar_usuario_especialidad);
            console.log("return_usuario_especialidad guardar_usuario_especialidad: " , guardar_usuario_especialidad.btn_guardar);

            
            this.dialogUsuarioInstitucion = false
            this.$emit("guardar_usuario_especialidad_datos_basicos", guardar_usuario_especialidad);
            //this.send_paciente = msg;
        },    

    },
    computed: {
    ...mapGetters(['fields_usuario_especialidad', 'fields_usuario']),
        listaPacientes () {
        return this.datos_paciente.map(entry => {
            console.log("tx_nombres : ", entry.tx_nombre_completo)
            const Description = entry.tx_nombre_completo.length > this.descriptionLimit
            ? entry.tx_nombre_completo.slice(0, this.descriptionLimit) + '...'
            : entry.tx_nombre_completo

            return Object.assign({}, entry, { Description })
        })
        },    
    }, 
    components: {
        UsuarioInstitucionRegistrar,
        
    },
    mounted() {
        //this.searchPaciente()
    },     
}
</script>