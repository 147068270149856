<template>
  <div class="">
      <div>
        <UsuarioHeader :titulo="titulo" /> 

      </div>  
    <div class="md:flex md:items-start">
      <div class="w-full md:w-1/6">
          <ul class="
            nav nav-tabs nav-justified
            flex flex-col
            flex-wrap
            list-none
            border-2
            pl-0
            mb-4
          " id="tabs-tabJustify" role="tablist">
            <li class="nav-item flex-grow border-1" role="presentation">
              <a href="#tabs-usuarioJustify" class="
                nav-link
                w-full
                block
                font-medium
                text-sm
                leading-tight
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="tabs-usuario-tabJustify"   data-bs-toggle="pill" data-bs-target="#tabs-usuarioJustify" role="tab"
                aria-controls="tabs-usuarioJustify" aria-selected="true">Datos básicos</a>
            </li>
            <li class="nav-item flex-grow border-t-2"  role="presentation">
              <a href="#tabs-usuario_perfilesJustify" class="
                nav-link
                w-full
                block
                font-medium
                leading-tight
                text-sm
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-usuario_perfiles-tabJustify" ref="tabs_usuario_perfiles_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-usuario_perfilesJustify" role="tab"
                aria-controls="tabs-usuario_perfilesJustify" aria-selected="false">
                Perfiles            
                </a>
            </li>
            <li class="nav-item flex-grow border-t-2"  role="presentation">
              <a href="#tabs-usuario_especialidadJustify" class="
                nav-link
                w-full
                block
                font-medium
                leading-tight
                text-sm
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-usuario_especialidad-tabJustify" ref="tabs_usuario_especialidad_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-usuario_especialidadJustify" role="tab"
                aria-controls="tabs-usuario_especialidadJustify" aria-selected="false">
                Filtros            
                </a>
            </li>
            <li class="nav-item flex-grow border-t-2" @click="cerrar({})" role="presentation">
              <a href="#tabs-cerrarJustify" class="
                nav-link
                w-full
                block
                font-medium
                text-sm
                leading-tight
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-cerrar-tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-cerrarJustify" role="tab"
                aria-controls="tabs-cerrarJustify" aria-selected="false">
                  Cerrar
                </a>
            </li>

          </ul>
        
      </div>
      <div class="tab-content w-full md:w-4/6 border-0" id="tabs-tabContentJustify">
          <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
            <NotificacionInterna :mensaje="mensaje" />  
          </div>

          <div class="tab-pane fade active show"  id="tabs-usuarioJustify" role="tabpanel"
            aria-labelledby="tabs-usuario-tabJustify">
              <div class=" border-2 rounded-md shadow-md">
                <UsuarioDatosBasicos titulo="" @guardar_usuario_datos_basicos="return_usuario_datos_basicos" :send_usuario="send_usuario"/>        
              </div>
          </div>
          <div class="tab-pane fade"  id="tabs-usuario_perfilesJustify" role="tabpanel"
            aria-labelledby="tabs-usuario_perfiles-tabJustify">
              <div class=" border-2 rounded-md shadow-md">
                <UsuarioPerfil titulo=""  :send_usuario="send_usuario"/>        
              </div>
              
          </div>
          <div class="tab-pane fade"  id="tabs-usuario_especialidadJustify" role="tabpanel"
            aria-labelledby="tabs-usuario_especialidad-tabJustify">
              <div class=" border-2 rounded-md shadow-md">
                <UsuarioInstitucion titulo="" @guardar_usuario_especialidad="return_datos_usuario_especialidad" :send_usuario="send_usuario"/>        
              </div>
              
          </div>

      </div>
      <div class="w-full md:w-1/6">
          
          <div>
            <div class="accordion accordion-flush text-sm" id="accordionFlushExample">
              <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingTwo">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                rounded-none
                px-5
                text-sm text-gray-800 text-left
                bg-white
                border-0
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                    aria-expanded="false" aria-controls="flush-collapseTwo">
                    Consultas médicas anteriores
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                    demonstrate
                    the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this
                    being
                    filled with some actual content.</div>
                </div>
              </div>              
              <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingTwo">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                rounded-none
                px-5
                text-sm text-gray-800 text-left
                bg-white
                border-0
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                    aria-expanded="false" aria-controls="flush-collapseTwo">
                    Agenda
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                    demonstrate
                    the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this
                    being
                    filled with some actual content.</div>
                </div>
              </div>
              <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingThree">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                px-5
                text-sm text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                    aria-expanded="false" aria-controls="flush-collapseThree">
                    Seguridad
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">
                    <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                      {{ index }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingPlantilla">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                px-5
                text-base text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePlantilla"
                    aria-expanded="false" aria-controls="flush-collapsePlantilla">
                    Plantilla
                  </button>
                </h2>
                <div id="flush-collapsePlantilla" class="accordion-collapse collapse" aria-labelledby="flush-headingPlantilla"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                    demonstrate
                    the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the space to make it look, at least at first
                    glance,
                    a bit more representative of how this would look in a real-world application.</div>
                </div>
              </div>          
            </div>   
            <div  class="py-4 px-5 bg-white  border-gray-200">
              <span>Última consulta</span>
                <div>
                  <div>
                  </div>
                  <div>
                    <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                      {{ locales.grupos[index].caracteristicas.label  }}
                      {{ locales.grupos[index][index]  }}
                    </div>              
                </div>
                </div>

            </div>
          </div>
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_USUARIO_TEMP, UPDATE_USUARIO,  LIST_USUARIO } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import UsuarioDatosBasicos from "@/components/configuracion/Seguridad/Usuario/UsuarioDatosBasicos";
import UsuarioPerfil from "@/views/configuracion//Seguridad/Usuario/UsuarioPerfil";
import UsuarioInstitucion from "@/views/configuracion//Seguridad/Usuario/UsuarioInstitucion";

import UsuarioHeader from "@/components/comunes/HeaderModulo.vue";

const moment = require('moment');

  export default {
    name: 'UsuarioActualizar',
    data() {
        return {
          consultasmedica: [],
          tabs: {
            group_usuario:{
              active: '',
              class: ''
            },
          },
          fields_usuario_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupos:'',
            fields_usuario: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_usuario','fields_persona']),
        hoy() {
            const today = new Date();
            var fecha2 = moment(today);
            return fecha2.format('DD-MM-YYYY');
        },            

    },    
    props: {
        send_usuario: Object,
        send_motivo: Object,
        titulo: String
    },
    components: {
      NotificacionInterna,
      UsuarioDatosBasicos,
      UsuarioPerfil,
      UsuarioInstitucion,      
      UsuarioHeader,
    },
    methods: {
        async limpiar () {
            for (const property in this.fields_usuario) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_usuario[property]) {
                          this.fields_usuario[property][property_01][property_01] = ''
                      }
                  }
            }
            for (const property in this.fields_persona) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_persona[property]) {
                          this.fields_persona[property][property_01][property_01] = ''
                      }
                  }
            }



        },
        searchUsuario(q) {
          console.log('Paciente: ', q)
          this.$store.dispatch(LIST_USUARIO, {nu_id_usuario: q}).then(response => {
              this.consultasmedica = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },  
        handleClick(e) {
          this.locales.grupos = this.fields_usuario.[e]
        },      
        cerrar(msg) {
          console.log('actualizar_datos_usuario: Actualizar')
          this.$emit("actualizar_datos_usuario", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },
        return_datos_usuario_especialidad(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            guardar.json_especialidad.json_especialidad = JSON.stringify(guardar.json_especialidad.json_especialidad)
            const formData = new FormData()
            formData.append('usuario', JSON.stringify(guardar))
            formData.append('nu_id_usuario', this.fields_usuario.group_datos_basicos.nu_id_usuario.nu_id_usuario)

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              this.$store.dispatch(UPDATE_USUARIO, formData).then(datos => {
                guardar.btn_guardar = 'true';
                  this.mensaje.satisfactorio.texto = "Modificación de la institución " + JSON.parse(datos.datos_usuario).nu_id_usuario + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                  //this.$refs.tabs_contacto_tabJustify.click();
              
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })


          /// hasta aca optimizar
        },    
        return_usuario_datos_basicos(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            console.log('this.send_usuario.operacion: ', this.send_usuario.operacion)
            const formData = new FormData()
            formData.append('usuario', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (this.send_usuario.operacion === 'EDITAR') {
                formData.append('oper', 'EDITAR')
                
                this.$store.dispatch(UPDATE_USUARIO, formData).then(datos => {
                  guardar.btn_guardar = 'true';
                  this.fields_usuario.group_datos_basicos.nu_id_usuario.nu_id_usuario = datos.nu_id_usuario
                  //this.fields_usuario.group_usuario.tx_usuario.tx_usuario = datos.tx_usuario
                  setTimeout(() => {
                      // this.isLoading = false
                        console.log('message emit from child component', datos)
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                        });      

                    },3000)                  
                    this.$refs.tabs_enfermedadactual_tabJustify.click();

                  
                }, error => {
                    guardar.btn_guardar = 'false';
                    this.$store.commit(SET_ERROR, error.statusText)
                })

            } else {
              //formData = JSON.parse(JSON.stringify(guardar.formData))

              formData.append('oper', 'REGISTRO')

              this.$store.dispatch(CREATE_USUARIO_TEMP, formData).then(datos => {
                  //this.fields_usuario.group_datos_basicos.nu_id_usuario.nu_id_usuario = JSON.parse(datos.datos_usuario).tx_username
                  //this.mensaje.satisfactorio.texto = "Usuario temporal (" + JSON.parse(datos.datos_usuario).tx_username + "), revise su correo " . JSON.parse(datos.datos_usuario).tx_username;
                  this.mensaje.satisfactorio.texto = "Usuario temporal , revise su correo ";
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_usuario));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$refs.tabs_usuario_perfiles_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro fallido: " + error.data.error
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             

              })  
          }     

          /// hasta aca optimizar
            
        },    



    },
    mounted() {
      if (this.send_usuario.operacion === 'EDITAR') {
        
            for (const property in this.fields_usuario) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_usuario[property]) {
                        console.log("json_usuario_institucion  shshshs: ",  property_01)
                          if (this.fields_usuario[property][property_01]['caracteristicas']['tipo'] === 'JSON') {
                            this.fields_usuario[property][property_01][property_01] = JSON.parse(this.send_usuario.usuario[property_01])
                          } else {
                              this.fields_usuario[property][property_01][property_01] = this.send_usuario.usuario[property_01]
                          }

                      }
                  }
            }

          //this.fields_usuario.group_datos_basicos.json_institucion.json_institucion = JSON.parse(this.send_usuario.usuario.json_institucion)

          //this.fields_usuario.group_datos_basicos.json_tipo_persona.json_tipo_persona = JSON.parse(this.send_usuario.usuario.json_tipo_persona)

      } else {
              if (this.send_usuario.operacion === 'REGISTRAR') {
                  this.limpiar()
              }
      }            
    },         

    
  }
</script>

<style lang="scss" scoped>

</style>