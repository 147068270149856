<template>
  <div class="">
      <div>
        <PerfilHeader :titulo="titulo" /> 
      </div>  
    <div class="flex space-x-2 justify-center">
      <button @click="update_datos({})"  type="button" class="w-full inline-block px-6 py-2.5 bg-blue-200 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Editar</button>
    </div>      
    <div class="md:flex md:items-start">
      <div class="w-full md:w-1/6">
          <ul class="
            nav nav-tabs nav-justified
            flex flex-col
            flex-wrap
            list-none
            border-2
            pl-0
            mb-4
          " id="tabs-tabJustify" role="tablist">
            <li class="nav-item flex-grow border-1" role="presentation">
              <a href="#tabs-perfilJustify" class="
                nav-link
                w-full
                block
                font-medium
                text-sm
                leading-tight
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="tabs-perfil-tabJustify"   data-bs-toggle="pill" data-bs-target="#tabs-perfilJustify" role="tab"
                aria-controls="tabs-perfilJustify" aria-selected="true">Datos básicos</a>
            </li>
            <li class="nav-item flex-grow border-t-2" @click="cerrar({})" role="presentation">
              <a href="#tabs-cerrarJustify" class="
                nav-link
                w-full
                block
                font-medium
                text-sm
                leading-tight
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-cerrar-tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-cerrarJustify" role="tab"
                aria-controls="tabs-cerrarJustify" aria-selected="false">
                  Cerrar
                </a>
            </li>

          </ul>
        
      </div>
      <div class="tab-content w-full md:w-4/6 border-0" id="tabs-tabContentJustify">
          <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
            <NotificacionInterna :mensaje="mensaje" />  
          </div>

          <div class="tab-pane fade active show"  id="tabs-perfilJustify" role="tabpanel"
            aria-labelledby="tabs-perfil-tabJustify">
              <div class=" border-2 rounded-md shadow-md">
                <PerfilDatosBasicos titulo="" @guardar_perfil_datos_basicos="return_perfil_datos_basicos" :send_perfil="send_perfil"/>        
              </div>
          </div>
      </div>
      <div class="w-full md:w-1/6">
          
          <div>
            <div class="accordion accordion-flush text-sm" id="accordionFlushExample">
              <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingTwo">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                rounded-none
                px-5
                text-sm text-gray-800 text-left
                bg-white
                border-0
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                    aria-expanded="false" aria-controls="flush-collapseTwo">
                    Últimas actualizaciones 
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                    demonstrate
                    the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this
                    being
                    filled with some actual content.</div>
                </div>
              </div>              
              <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingTwo">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                rounded-none
                px-5
                text-sm text-gray-800 text-left
                bg-white
                border-0
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                    aria-expanded="false" aria-controls="flush-collapseTwo">
                    Agenda
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                    demonstrate
                    the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this
                    being
                    filled with some actual content.</div>
                </div>
              </div>
              <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingThree">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                px-5
                text-sm text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                    aria-expanded="false" aria-controls="flush-collapseThree">
                    Seguridad
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">
                    <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                      {{ index }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="flush-headingPlantilla">
                  <button class="accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                px-5
                text-base text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePlantilla"
                    aria-expanded="false" aria-controls="flush-collapsePlantilla">
                    Plantilla
                  </button>
                </h2>
                <div id="flush-collapsePlantilla" class="accordion-collapse collapse" aria-labelledby="flush-headingPlantilla"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                    demonstrate
                    the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the space to make it look, at least at first
                    glance,
                    a bit more representative of how this would look in a real-world application.</div>
                </div>
              </div>          
            </div>   
            <div  class="py-4 px-5 bg-white  border-gray-200">
              <span>Última consulta</span>
                <div>
                  <div>
                  </div>
                  <div>
                    <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                      {{ locales.grupos[index].caracteristicas.label  }}
                      {{ locales.grupos[index][index]  }}
                    </div>              
                </div>
                </div>

            </div>
          </div>
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_PERFIL, UPDATE_PERFIL,  LIST_PERFIL } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import PerfilDatosBasicos from "@/components/configuracion/PerfilDatosBasicos";

import PerfilHeader from "@/components/comunes/HeaderModulo.vue";


  export default {
    data() {
        return {
          consultasmedica: [],
          tabs: {
            group_perfil:{
              active: '',
              class: ''
            },
          },
          fields_perfil_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupos:'',
            fields_perfil: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_perfil','fields_persona'])
    },    
    props: {
        send_perfil: Object,
        titulo: String
    },
    components: {
      NotificacionInterna,
      PerfilDatosBasicos,
      PerfilHeader,
    },
    methods: {
        async limpiar () {
            for (const property in this.fields_perfil) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_perfil[property]) {
                          this.fields_perfil[property][property_01][property_01] = ''
                      }
                  }
            }
            this.fields_perfil.group_datos_basicos.json_opcion.json_opcion = []
        },
        update_datos() {
          this.fields_perfil.isEditing = !this.fields_perfil.isEditing 
        },
        searchPerfil(q) {
          console.log('Paciente: ', q)
          this.$store.dispatch(LIST_PERFIL, {nu_id_perfil: q}).then(response => {
              this.consultasmedica = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },  
        cerrar(msg) {
          //console.log('actualizar_datos_perfil: Actualizar')
          this.$emit("actualizar_datos_perfil", msg);
        },    
        // Paso #2
        return_perfil_datos_basicos(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            console.log('this.send_perfil.operacion: ', this.send_perfil.operacion)
            const formData = new FormData()
            formData.append('perfil', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (this.send_perfil.operacion === 'EDITAR') {
                formData.append('oper', 'EDITAR')
                
                this.$store.dispatch(UPDATE_PERFIL, formData).then(datos => {
                  guardar.btn_guardar = 'true';
                  this.fields_perfil.group_datos_basicos.nu_id_perfil.nu_id_perfil = datos.nu_id_perfil
                  //this.fields_perfil.group_perfil.tx_perfil.tx_perfil = datos.tx_perfil
                  setTimeout(() => {
                      // this.isLoading = false
                        console.log('message emit from child component', datos)
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                        });      

                    },3000)                  
                    this.$refs.tabs_enfermedadactual_tabJustify.click();

                  
                }, error => {
                    guardar.btn_guardar = 'false';
                    this.$store.commit(SET_ERROR, error.statusText)
                })

            } else {
              //formData = JSON.parse(JSON.stringify(guardar.formData))

              formData.append('oper', 'REGISTRO')

              this.$store.dispatch(CREATE_PERFIL, formData).then(datos => {
                  this.fields_perfil.group_datos_basicos.nu_id_perfil.nu_id_perfil = datos.datos_perfil
                  this.mensaje.satisfactorio.texto = "Perfil  " + datos.datos_perfil + ", fue creado satistactoriamente";
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                        this.$emit("actualizar_datos_perfil", JSON.stringify(this.fields_perfil));
                        console.log('message emit from child component', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro fallido: " + error.data.error
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             

              })  
          }     

          /// hasta aca optimizar
            
        },    



    },
    mounted() {
      if (this.send_perfil.operacion === 'EDITAR') {
            for (const property in this.fields_perfil) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_perfil[property]) {
                          this.fields_perfil[property][property_01][property_01] = this.send_perfil.perfil[property_01]
                      }
                  }
            }
          this.fields_perfil.group_datos_basicos.json_opcion.json_opcion = JSON.parse(this.send_perfil.perfil.json_opcion)

      } else {
              if (this.send_perfil.operacion === 'REGISTRAR') {
                  this.limpiar()
              }
      }            
    },         

    
  }
</script>

<style lang="scss" scoped>

</style>