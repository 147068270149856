 <template>
  <div class="w-full p-2">

      <div>
        <SeguridadOpciones :seguridad="datos_seguridad"></SeguridadOpciones>
      </div>

  </div>
</template>

<script>
import SeguridadOpciones from "@/views/configuracion/Seguridad/SeguridadOpciones.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { LIST_ESPECIALIDAD } from '@/store/actions.type'

export default {
  name: 'Seguridad',
  data () {
    return {
      titulo: 'Seguridad',
      datos_seguridad: [],
      send_seguridad: {},

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    SeguridadOpciones
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    searchSeguridad(q) {
      this.$store.dispatch(LIST_ESPECIALIDAD, {q: q}).then(response => {

          this.datos_seguridad = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_seguridad(msg) {
      this.searchSeguridad({q: msg})

      //this.send_seguridad = msg;
    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_seguridad = msg;
        //this.select_seguridad = JSON.parse(msg);
        console.log("return_consulta_medica guardar_seguridad update: " , guardar_seguridad);

        
        if (guardar_seguridad.btn_guardar == 'true') {
            this.searchSeguridad({})
            //this.fields.seguridad = this.select_seguridad.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_seguridad.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_seguridad.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_seguridad = msg;
    },        

  },
  mounted () {
    this.searchSeguridad({})
    /*  this.$store.dispatch(READ_ESPECIALIDAD, {}).then(response => {

          this.datos_seguridad = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
