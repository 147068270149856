var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_usuario.group_datos_basicos.json_institucion_perfil.json_institucion_perfil,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.json_perfil",fn:function(ref){
var item = ref.item;
return _vm._l((item.json_perfil),function(item_esp,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item_esp.tx_nombre_perfil)+" ")])})}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Asociar Usuario a Perfil ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"institucion_perfil.json_institucion_perfil","rules":_vm.fields_usuario.group_datos_basicos.json_institucion_perfil.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.fields_usuario.group_datos_basicos.json_institucion.json_institucion,"item-text":"tx_razon_social","item-value":"nu_id_institucion","placeholder":_vm.fields_usuario.group_datos_basicos.json_institucion_perfil.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_usuario.group_datos_basicos.json_institucion_perfil.caracteristicas.label,"outlined":"","return-object":"","disabled":!_vm.fields_usuario.isEditing},model:{value:(_vm.institucion_perfil.json_institucion_perfil),callback:function ($$v) {_vm.$set(_vm.institucion_perfil, "json_institucion_perfil", $$v)},expression:"institucion_perfil.json_institucion_perfil"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"institucion_perfil.json_perfil","rules":_vm.fields_usuario.group_datos_basicos.json_perfil.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.list_perfiles,"error-messages":errors,"chips":"","outlined":"","color":"blue-grey lighten-2","label":_vm.fields_usuario.group_datos_basicos.json_perfil.caracteristicas.label,"item-text":"tx_nombre_perfil","item-value":"nu_id_perfil","multiple":"","return-object":"","disabled":!_vm.fields_usuario.isEditing},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.tx_nombre_perfil)+" ")])]}}],null,true),model:{value:(_vm.institucion_perfil.json_perfil),callback:function ($$v) {_vm.$set(_vm.institucion_perfil, "json_perfil", $$v)},expression:"institucion_perfil.json_perfil"}})]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Guardar")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }