<template>
    <div class="text-skin-base w-full">
                <div class="md:flex  justify-end md:justify-start w-full">                  
                    <div class="my-auto p-2 md:pl-10 w-full md:w-1/5">
                        <button @click="registrar_usuario()" class="w-full font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-4 mt-1 m-1">
                            Crear usuario
                        </button>                
                    </div>
<!--                            @change="verificar_frecuencia()"                        
-->        
                </div>


        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogUsuario" :value="true" @input="dialogUsuario = false"                   
            >
            <v-card>
              <v-container>
                <!--<div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Historia clínica: </span><span><strong>{{ fields_usuario.group_usuario.tx_usuario.tx_usuario }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_usuario.group_usuario.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                
-->
                <div class="w-full md:w-1/2 mx-auto my-auto">

                    <UsuarioTemporalActualizar titulo="Registrar Usuario" @actualizar_datos_usuario="return_usuario" :send_usuario="send_usuario"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import { READ_PACIENTE } from '@/store/actions.type'
//import { SET_ERROR } from '@/store/mutations.type'
import UsuarioTemporalActualizar from "@/views/configuracion/Seguridad/Usuario/UsuarioTemporalActualizar.vue";

export default {
    name: 'UsuarioOpciones',
    data() {
        return {
            dialogUsuario: false,
            buscarPaciente: false, 
            send_usuario: {},
            send_motivo: {},
            datos_usuario: [],
            isLoading: false,
        }
    },
    props: {
        titulo: String

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    
        registrar_usuario () {
            this.dialogUsuario = true
            this.send_usuario.operacion = 'REGISTRAR'
            this.fields_usuario.isEditing = true
            this.fields_persona.isEditing = true
            // console.log(value) // someValue
        },
        return_usuario(msg) { 
            
            console.log('actualizar_datos_usuario: Opciones')
   
            this.$emit("actualizar_datos_usuario", msg);
            this.dialogUsuario = false
        },    

    },
    computed: {
        ...mapGetters(['fields_usuario','fields_persona','currentUser']),
    },
    components: {
        UsuarioTemporalActualizar,
    },
    mounted() {
        //this.searchPaciente()
    },     
}
</script>