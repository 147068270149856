<template>
    <div>

        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="perfil"
                    :items-per-page="5"
                    :expanded.sync="expanded"   
                    item-key="nu_id_perfil"
                    show-expand                                     
                    :search="search"        
                    class="elevation-1"
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="registrar_consulta(item)"
                        >
                          {{ icons.mdiBookPlusMultiple }}
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
              <!--          <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                              <span class="font-bold">Opciones</span>
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_especialidad)" :key="index">      
                                {{ item_01.tx_especialidad }}
                              </div>                            
                          </td>
                        </template>                                                  
                        -->
                  </v-data-table>
              </div>
    </div>

        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogPerfil" :value="true" @input="dialogPerfil = false"                   
            >
            <v-card>
              <v-container>

                <!--<div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Historia clínica: </span><span><strong>{{ fields_perfil.group_perfil.tx_perfil.tx_perfil }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_perfil.group_perfil.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                
-->
                <div class="w-full mx-auto my-auto">

                    <PerfilActualizar titulo="Editar perfil" @actualizar_datos_perfil="return_perfil" :send_perfil="send_perfil"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
import { LIST_PERFIL } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import PerfilActualizar from "@/components/configuracion/PerfilActualizar.vue";

export default {
    name: 'PerfilListar',
    data() {
        return {
            dialogPerfil: false, 
            send_perfil: {},
            search: '',
            titulo: 'Perfil',
            perfil_01: [],
            expanded: [],            
            headers: [
              {
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'nu_id_perfil',
              },
              {
                text: 'Perfil',
                sortable: true,
                value: 'tx_desc_perfil',
              },                                  
              {
                text: 'Descripción',
                sortable: true,
                value: 'tx_nombre_perfil',
              },          
              { 
                text: 'Status', 
                value: 'sw_status' 
              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },
              { 
                text: '', 
                value: 'data-table-expand' 
              },                        
            ],            
        }
    },
    props: {
        perfil: []

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_perfil.perfil = id
      this.send_perfil.operacion = 'EDITAR'
      this.dialogPerfil = true
    },
    return_perfil(msg) {    
        this.$emit("actualizar_datos_perfil", msg);
        this.searchPerfil('')
        this.dialogPerfil = false
    },    
    searchPerfil(q) {
      this.$store.dispatch(LIST_PERFIL, {nu_id_perfil: q}).then(response => {
          this.perfil = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
  
    }, 
    components: {
      PerfilActualizar
    },
    mounted() {
      this.searchPerfil({})
      //this.perfil_01 = this.perfil; 
      //console.log('Perfils: ', this.perfil)
    },
    watch: {
    },    


}
</script>